import React, { useRef, useState, useEffect } from 'react'
import { Typography, Container } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Link from '@mui/material/Link'
import { GithubIcon, HuggingFaceIcon } from '../Components/Icons'
import type { IProjectsContent } from '../Data/base'

const getSourceIcon = (sourceType?: string) => {
    if (!sourceType) return null

    if (sourceType === 'github') {
        return GithubIcon
    }

    if (sourceType === 'huggingface') {
        return HuggingFaceIcon
    }

    return null
}

const ProjectsContainer = styled(Container)(() => {
    return {
        paddingLeft: '0 !important',
        paddingRight: '0 !important'
    }
})

const TitleText = styled(Typography)(({ theme }) => {
    return {
        color: theme.palette.secondary.main,
        textAlign: 'left',
        width: 'fit-content'
    }
})
const DescriptionText = styled(Typography)(({ theme }) => {
    return {
        color: theme.palette.secondary.main,
        textAlign: 'left',
        width: 'fit-content'
    }
})

const DescriptionTextLink = styled(Link)(({ theme }) => {
    return {
        color: theme.palette.secondary.main,
        textAlign: 'left',
        fontWeight: 'bold',
        textDecoration: 'underline',
        width: 'fit-content'
    }
})

const SourceLinkContainer = styled(Box)(({ theme }) => {
    return {
        ...theme.typography.body2,
        padding: 0,
        textAlign: 'center',
        display: 'flex',
        color: theme.palette.text.secondary,
        fontSize: '1.5em'
    }
})

const Layout: React.FC<{ type: string, title?: string, sectionContents: IProjectsContent[] }> = ({
    title,
    sectionContents
}) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const [canRenderIframe, setCanRenderIframe] = useState(false)

    useEffect(() => {
        if (containerRef.current) {
            if (containerRef.current.offsetWidth > 0) {
                setCanRenderIframe(true)
            }
        }
    }, [containerRef.current])

    return (
        <ProjectsContainer>
            <Stack ref={containerRef} sx={{ paddingLeft: 4, paddingRight: 4 }} direction='column' spacing={2}>
                <TitleText fontSize='34px' >{title}</TitleText>
                {
                    sectionContents.map((sectionItemContent, index) => {
                        const TitleComponent = sectionItemContent.link ? DescriptionTextLink : DescriptionText
                        const SourceIconComponent = getSourceIcon(sectionItemContent.sourceType)

                        return <Box sx={{ paddingBottom: 4 }} key={index} component="div">
                            <Stack direction="column" spacing={2}>

                                <Stack direction="row" spacing={2}>
                                    <TitleComponent target='_blank' href={sectionItemContent.link} fontSize='22px'>
                                        {sectionItemContent.title}
                                    </TitleComponent>
                                    {
                                        SourceIconComponent
                                            ? <SourceLinkContainer>
                                                <SourceIconComponent link={sectionItemContent.sourceLink} />
                                            </SourceLinkContainer>
                                            : null
                                    }
                                </Stack>

                                {sectionItemContent.description.map(
                                    (descriptionItem, index) => {
                                        if (descriptionItem === '$PRINT_SPACE') {
                                            return <DescriptionText display='none' displayPrint='block' key={index} fontSize='16px'> {' '}</DescriptionText>
                                        }

                                        return <DescriptionText key={index} fontSize='16px'> {descriptionItem}</DescriptionText>
                                    }
                                )}

                                {
                                    canRenderIframe && sectionItemContent.demo
                                        ? <Box sx={{ displayPrint: 'none' }}>
                                            <sectionItemContent.demo.Render
                                                height={sectionItemContent.demo.height ?? 500}
                                                frameBorder={0}
                                                width={containerRef.current?.offsetWidth ? containerRef.current.offsetWidth - 32 : 1066}
                                            />
                                        </Box>
                                        : null
                                }
                            </Stack>
                        </Box>
                    })
                }
            </Stack>

            {/* <Timeline sx={{ marginTop: 0, padding: 0 }} position='right'>
                {
                    sectionContents.map((sectionItemContent, index) => {
                        return <MyTimelineItem
                            key={index}
                            isLast={index === 1}
                            img={sectionItemContent.img}
                            title={sectionItemContent.title}
                            from={sectionItemContent ch.from}
                            to={sectionItemContent.to}
                            description={sectionItemContent.description}
                        />
                    })
                }
            </Timeline> */}
        </ProjectsContainer>
    )
}

export default Layout
