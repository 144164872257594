import * as React from 'react'

import { Box } from '@material-ui/core'
import { styled } from '@mui/material/styles'

export interface ImageProps {
    src?: string
    alt?: string

    size?: number
    rounded?: number
    margin?: string
}

type ImageCompProps = Pick<ImageProps, 'rounded' | 'size' | 'margin'>

const ImageComp = styled('img')<ImageCompProps>`
    border-radius: ${(props) => props.rounded ?? 0}%;
    width: ${(props) => props.size}px;
    margin: ${(props) => (props.margin ? props.margin : '0 0 0 0')};
`

const Image: React.FC<ImageProps> = ({ src, alt, size, rounded }) => {
    return (
        <Box component="div">
            <ImageComp
                rounded={rounded}
                size={size}
                src={src}
                alt={alt ?? 'Image'}
            />
        </Box>
    )
}

export default Image
