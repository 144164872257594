import React from 'react'

import { ReactComponent as HuggingFaceSVGRaw } from '../assets/hugging-face.svg'

import { Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'

import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const HuggingFaceSVG = styled(HuggingFaceSVGRaw)(({ theme }) => {
    return {
        color: 'red',
        width: '48px'
    }
})

const GithubContainer = styled('div')(({ theme }) => {
    return {
        height: 'auto',
        width: 'auto',
        ':hover': {
            cursor: 'pointer'
        }
    }
})

const HuggingFaceContainer = styled('div')(({ theme }) => {
    return {
        height: 'auto',
        width: 'auto',
        ':hover': {
            cursor: 'pointer'
        }
    }
})

export const GithubIcon = ({ link, ...iconProps }: any = {}) => (<GithubContainer
    onClick={() => {
        global.window
            ?.open(link, '_blank')
            ?.focus()
    }}
>
    <Tooltip title="Github">
        <FontAwesomeIcon {...iconProps} icon={faGithub} href={link} />
    </Tooltip>
</GithubContainer>)

export const HuggingFaceIcon = ({ link, ...iconProps }: any = {}) => (<HuggingFaceContainer
    onClick={() => {
        global.window
            ?.open(
                link,
                '_blank'
            )
            ?.focus()
    }}
>
    <Tooltip title="Hugging Face">
        <HuggingFaceSVG {...iconProps} href={link} />
    </Tooltip>
</HuggingFaceContainer>)
