import * as React from 'react'

import data from '../Data/base'
import type { ITimelineContent, IProjectsContent } from '../Data/base'

import { v4 as uuid } from 'uuid'

import Stack from '@mui/material/Stack'
import { Container } from '@mui/material'

import Divider from '../Components/Divider'

import Header from './Header'
import Timeline from './Timeline'
import Projects from './Projects'

const Layout: React.FC = () => {
    return (
        <Container>
            <Stack spacing={4}>
                <Header key={uuid()} content={data.header} />

                {
                    data.sections.map((section, index) => {
                        let component

                        if (section.type === 'timeline') {
                            component = <Timeline key={uuid()}
                                type={section.type}
                                title={section.title}
                                sectionContents={section.content as ITimelineContent[]}
                            />
                        }

                        if (section.type === 'projects') {
                            component = <Projects key={uuid()}
                                type={section.type}
                                title={section.title}
                                sectionContents={section.content as IProjectsContent[]}
                            />
                        }

                        return <>
                            <Divider marginTop={index ? 1 : 0} key={uuid()} height={1} color='secondary' />
                            {component}
                        </>
                    })
                }

            </Stack>
        </Container>
    )
}

export default Layout
