import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { styled } from '@mui/material/styles'

import './App.css'
import Layout from './Modules/Layout'

const RoutesContainer = styled('div')(({ theme }) => {
    return {
        backgroundColor: theme.palette.primary.main
    }
})

const App = () => {
    return (
        <RoutesContainer>
            <Routes>
                <Route path="/" element={<Layout />} />
            </Routes>
        </RoutesContainer>
    )
}

export default App
