import { createTheme } from '@mui/material/styles'

const theme = createTheme({
    palette: {
        background: {
            default: '#FFFFFF',
            paper: '#FFFFFF'
        },
        primary: { main: '#FFFFFF' },
        secondary: { main: '#4c4c4c', light: '#bbb', dark: '#333' }
    }
})

export default theme
