import * as React from 'react'

import { styled } from '@mui/system'
import { PaletteColor } from '@mui/material/styles'

import PropTypes from 'prop-types'

interface DividerProps {
    height?: number
    color?: 'primary' | 'secondary'
    marginTop?: number
}

const StyledDivider = styled('div', {
    shouldForwardProp: (prop) => prop !== 'height' && prop !== 'color' && prop !== 'taper'
})<DividerProps>(({ theme, height = 1, color = 'primary' }) => {
    const colorValue = (theme.palette[color] as PaletteColor).main

    const gradient = `linear-gradient(to left, transparent, ${colorValue},${colorValue},${colorValue}, transparent)`

    return {
        height,
        padding: 0,
        backgroundImage: gradient,
        border: 'none'
    }
})

const CustomDivider: React.FC<DividerProps> = ({ height, color, marginTop }) => {
    return <StyledDivider sx={{ marginTop: `${marginTop ?? 0} !important` }} height={height} color={color} />
}

CustomDivider.propTypes = {
    height: PropTypes.number,
    color: PropTypes.oneOf(['primary', 'secondary'])
}

export default CustomDivider
