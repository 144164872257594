import * as React from 'react'

import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import Image from '../Components/Image'
import type { IBaseData } from '../Data/base'

const Container = styled(Box)(({ theme }) => {
    return {
        // backgroundColor: 'grey',
        backgroundColor: theme.palette.primary.main,
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    }
})

const NameTitle = styled(Link)(({ theme }) => {
    return {
        color: theme.palette.secondary.main,
        textAlign: 'left',
        '@media print': {
            textDecoration: 'underline'
        },
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }

    }
})

const AboutMe = styled(Typography)(({ theme }) => {
    return {
        color: theme.palette.secondary.light,
        textAlign: 'left',
        maxWidth: '270px',
        fontStyle: 'italic',
        marginTop: theme.spacing(1),

        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
            maxWidth: '999px'
        }
    }
})

const TextContainer = styled(Box)(({ theme }) => {
    return {
        // backgroundColor: 'blue',
        ...theme.typography.body2,
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column'
    }
})

const SocialContainer = styled(Stack)(({ theme }) => {
    return {
        ...theme.typography.body2,
        padding: `${theme.spacing(1)} 0 0 0`,
        textAlign: 'center',
        display: 'flex',
        color: theme.palette.text.secondary,
        fontSize: '2.85em',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'

        }
    }
})

const HeaderContainer = styled(Container)(({ theme }) => {
    return {
        marginTop: theme.spacing(2)
    }
})

const Header: React.FC<{ content: IBaseData['header'] }> = ({ content }) => {
    return (
        <HeaderContainer>
            <Image
                src={content.avatar.url}
                size={content.avatar.size}
                rounded={content.avatar.rounded}
            />
            <TextContainer>
                <NameTitle target='_blank' href='https://github.com/midyan' fontSize={content.info.name.size}>{content.info.name.content}</NameTitle>
                {
                    content.info.aboutMe
                        ? <AboutMe fontSize={content.info.aboutMe.size} >{content.info.aboutMe.content}</AboutMe>
                        : null
                }
                <SocialContainer displayPrint='none' spacing={2} direction="row">
                    {content.social.map((soc, key) => {
                        return <soc.Icon key={key} />
                    })}
                </SocialContainer>
            </TextContainer>
        </HeaderContainer>
    )
}

export default Header
