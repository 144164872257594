import React from 'react'
import Iframe from 'react-iframe'
import type { IIframe } from 'react-iframe/types'

import avatar from '../assets/avatar.png'
import tier from '../assets/tier.jpeg'
import campai from '../assets/campai.png'
import enegep from '../assets/enegep.png'
import tag from '../assets/tag.png'
import blank from '../assets/blank.png'

import { GithubIcon } from '../Components/Icons'
// import { GithubIcon, HuggingFaceIcon } from '../Components/Icons'

const data = {
    header: {
        info: {
            name: {
                content: 'Yan Nunes',
                size: '34px'
            },
            aboutMe: {
                content: 'I make awesome things 🦾🤖',
                size: '18px'
            }
        },
        avatar: {
            url: avatar,
            rounded: 50,
            size: 240
        },
        social: [
            {
                name: 'Github',
                Icon: (props: {}) => (
                    <GithubIcon link="https://github.com/midyan" {...props} />
                ),
                url: 'https://github.com/midyan'
            }
            // ,
            // {
            //     name: 'Huggin Face',
            //     type: 'svg',
            //     Icon: (props: {}) => (
            //         <HuggingFaceIcon
            //             link="https://huggingface.co/spaces/midyan/my-models"
            //             {...props}
            //         />

            //     ),
            //     url: 'https://huggingface.co/spaces/midyan/my-models'
            // }
        ]
    },
    sections: [
        {
            type: 'timeline',
            content: [
                {
                    img: tier,
                    title: 'Tech Lead @ TIER Mobility',
                    place: 'Berlin - Germany (Remote)',
                    from: 2023,
                    techStack: [
                        'Typescript',
                        'MongoDB',
                        'Postgres',
                        'React',
                        'Kotlin',
                        'Kafka',
                        'AWS',
                        'Terraform',
                        'K8S'
                    ],
                    description: [
                        'Responsible for leading the technical development of the system that empowers mechanics to repair electric scooters and bikes.',
                        'I help a team of 3 developers to coordinate with stakeholders and the product team to define the best technical solutions for the problems we face.',
                        'I oversaw the global rollout of a custom system of tasks that improved the work efficiency of hundreds of mechanics in more than 300 cities in the US, Europe, and the Middle East.'
                    ]
                },
                {
                    img: blank,
                    title: 'Senior Backend Developer @ TIER Mobility',
                    place: 'Berlin - Germany (Remote)',
                    to: 2023,
                    from: 2021,
                    techStack: [
                        'Typescript',
                        'MongoDB',
                        'Postgres',
                        'React',
                        'Kotlin',
                        'Kafka',
                        'AWS',
                        'Terraform',
                        'K8S'
                    ],
                    description: [
                        'Responsible for maintaining and creating microservices that empower mechanics to repair electric scooters and bikes.',
                        'I designed and developed a spare part tracking system that allows mechanics to track the spare parts used in each repair and the cost of each repair.'
                    ]
                },
                {
                    img: campai,
                    title: 'Lead Software Engineer @ campai GmbH',
                    place: 'Berlin - Germany',
                    from: 2018,
                    to: 2021,
                    techStack: [
                        'NodeJS',
                        'MongoDB',
                        'React',
                        'ReactNative',
                        'RabbitMQ',
                        'Heroku'
                    ],
                    description: [
                        'Responsible for leading and managing the Development Sector of the company, as well as defining new patterns and architectures used in all development fronts for creating the flagship product of the company.',
                        'I coordinated the development of the membership and payments systems, as well as the overall accounting and financial systems of the product.'
                    ]
                },
                {
                    img: blank,
                    title: 'Full Stack Developer @ campai GmbH',
                    place: 'Berlin - Germany',
                    from: 2017,
                    to: 2018,
                    techStack: [
                        'NodeJS',
                        'MongoDB',
                        'React',
                        'ReactNative',
                        'RabbitMQ',
                        'Heroku'
                    ],
                    description: [
                        'I acted as a Full Stack developer and take part in all fronts for developing the flagship product of the company, the SaaS (Software as a Service) for managing the clubs.',
                        'I started the development of the membership and payments systems, as well as the overall accounting and financial systems of the product.'
                    ]
                },
                {
                    img: enegep,
                    title: 'Mobile App Developer @ ABEPRO - Brazilian Association of Industrial Engineering',
                    place: 'Salvador - Brazil',
                    from: 2017,
                    to: 2017,
                    techStack: [
                        'NodeJS',
                        'MongoDB',
                        'ReactNative',
                        'GCP',
                        'crossbar.io'
                    ],
                    description: [
                        'Enegep 2017 App (React Native) - I have developed an offline first, real-time-updated App for the ENEGEP 2017, the largest Production Engineering Congress in Brazil!',
                        'The app will help users navigate through the event schedule, allow them to evaluate the lectures, and make networking easier and more effective.',
                        '$PRINT_SPACE',
                        '$PRINT_SPACE',
                        '$PRINT_SPACE',
                        '$PRINT_SPACE',
                        '$PRINT_SPACE',
                        '$PRINT_SPACE',
                        '$PRINT_SPACE'
                    ]
                },
                {
                    img: tag,
                    title: 'Full Stack Developer @ TAG.mx',
                    place: 'Salvador - Brazil',
                    from: 2016,
                    to: 2017,
                    techStack: [
                        'NodeJS',
                        'MongoDB',
                        'Angular',
                        'Docker',
                        'crossbar.io'
                    ],
                    description: [
                        "Responsible for leading the core development team of the company's flagship product, a full-fledged ERP(Enterprise Resource Planning).",
                        'I helped the development of a dynamic real-time and robust application for warehouse control, sales, and accounting management, as well as a CRM platform.',
                        'It is capable of summarizing the entire management of a company into a single system, helping to reduce the cost of the operation by a significant amount.'
                    ]
                }
            ] as ITimelineContent[]
        },
        {
            title: 'Small Pet Projects',
            type: 'projects',
            content: [
                {
                    title: '🕸️ js-faiss',
                    sourceLink: 'https://github.com/midyan/js-faiss',
                    sourceType: 'github',
                    link: 'https://www.npmjs.com/package/js-faiss',
                    description: [
                        'Toy implementation of in-memory FAISS in NodeJS.',
                        'It is not supposed to be used in production, but it is a fun project to learn about FAISS and how it works. I will build a little visualization tool for it soon.'
                    ]
                },
                {
                    title: '🤖 Alpha Coder',
                    sourceLink: 'https://github.com/H11-Tech/alpha-coder',
                    sourceType: 'github',
                    link: 'https://github.com/H11-Tech/alpha-coder',
                    description: [
                        '🚧 UNDER CONSTRUCTION 🚧',
                        'Small AI assistant that will help you code. Nothing fancy, just wanted to check if something like this was possible and learn LLM along the way.',
                        'It is not ready and it is super crashy/buggy. Once the first version works correctly, I will open-source it.',
                        'The video below is just a little preview of what it looks like now',
                        '$PRINT_SPACE',
                        '$PRINT_SPACE'
                    ],
                    demo: {
                        Render(props: IFrameProps) {
                            const { height, width, ...otherProps } = props

                            const iFrameProps: IIframe = {
                                ...otherProps,
                                height: `${height}px`,
                                width: `${width}px`,
                                url: 'https://www.youtube.com/embed/7dbnn_uMzMY'
                            }

                            return <Iframe {...iFrameProps} />
                        }
                    }
                },
                {
                    title: '🧬 Simple Genetic Algorithm',
                    sourceLink: 'https://github.com/midyan/react-p5',
                    sourceType: 'github',
                    link: 'https://genetic-algo-demo.yannunes.com',
                    description: [
                        'A simple genetic algorithm that optimizes the path of the small rod agents to reach the target.',
                        'The DNA of each agent is an array of vectors that represent the direction of the agent in each frame.',
                        'The fitness function is the distance of the agent to the target and it is used to optimize the breeding of each generation to make it reach the target faster',
                        'I used p5.js to render this little scenario.'
                    ],
                    demo: {
                        Render(props: IFrameProps) {
                            const { height, width, ...otherProps } = props

                            const iFrameProps: IIframe = {
                                ...otherProps,
                                height: `${height}px`,
                                width: `${width}px`,
                                url: `https://genetic-algo-demo.yannunes.com?height=${height}&width=${width}`
                            }

                            return <Iframe {...iFrameProps} />
                        }
                    }
                },
                {
                    title: '🦠 Covid Cases Predictor',
                    description: [
                        'At the start of the pandemic, Brazil had super limited COVID Testing capabilities. I developed a model that could predict the number of cases for each Brazilian city.',
                        'It was a simple statistical model that tried to predict the spread curve of the pandemic based on the reported cases by the WHO.',
                        'The model now is extremely outdated, but it was a fun project to work on.'
                    ],
                    link: 'https://covid.yannunes.com',
                    demo: {
                        height: 750,
                        Render(props: IFrameProps) {
                            const { height, width, ...otherProps } = props

                            const iFrameProps: IIframe = {
                                ...otherProps,
                                height: `${height}px`,
                                width: `${width}px`,
                                url: 'https://covid.yannunes.com/'
                            }

                            return <Iframe {...iFrameProps} />
                        }
                    }
                }
            ] as IProjectsContent[]
        }
    ]
}

export interface IFrameProps extends Omit<IIframe, 'height' | 'width' | 'url'> {
    height: number
    width: number
}

export interface IProjectsContent {
    title: string
    sourceLink?: string
    sourceType?: string
    description: string[]
    link: string
    demo: {
        height?: number
        Render: React.FC<IFrameProps>
    }
}

export interface ITimelineContent {
    img: string
    title: string
    from: number
    place: string
    description: string[]
    to?: number
    techStack?: string[]
}

export type IBaseData = typeof data

export default data
