import * as React from 'react'

import { Box } from '@material-ui/core'
import Stack from '@mui/material/Stack'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'

import Image from './Image'

export interface ITimelineItem {
    img: string
    title: string
    from: number
    to?: number
    place: string
    description: string[]
    isLast?: boolean
    techStack?: string[]
}

const DescriptionText = styled(Typography)(({ theme }) => {
    return {
        color: theme.palette.secondary.main,
        textAlign: 'left'
    }
})

const TimelineText = styled(Typography)(({ theme }) => {
    return {
        color: theme.palette.secondary.light,
        textAlign: 'right',
        marginTop: '1px'
    }
})

const TimelineItemInnerContent: React.FC<
    Pick<ITimelineItem, 'description' | 'img' | 'title' | 'place' | 'techStack'>
> = ({ title, description, img, place, techStack }) => {
    let TechStack = null

    if (techStack && techStack.length > 0) {
        TechStack = (
            <DescriptionText fontSize="16px">
                <DescriptionText fontWeight="bold">Tech Stack:</DescriptionText>
                {techStack.join(', ')}
            </DescriptionText>
        )
    }
    return (
        <Box component="div">
            <Stack direction="row" spacing={2}>
                <Box component="div">
                    <Image src={img} size={80} rounded={10} />
                </Box>
                <Box component="div">
                    <Stack direction="column" spacing={2}>
                        <DescriptionText fontSize="22px">
                            {' '}
                            {title}
                        </DescriptionText>
                        <TimelineText
                            sx={{
                                margin: 0,
                                marginTop: '0 !important',
                                marginBottom: '0 !important',
                                textAlign: 'left'
                            }}
                            fontWeight="bold"
                            fontSize="14px"
                        >
                            {' '}
                            {place}
                        </TimelineText>

                        {description.map((descriptionItem, index) => {
                            if (descriptionItem === '$PRINT_SPACE') {
                                return (
                                    <DescriptionText
                                        display="none"
                                        displayPrint="block"
                                        key={index}
                                        fontSize="16px"
                                    >
                                        {' '}
                                    </DescriptionText>
                                )
                            }

                            return (
                                <DescriptionText key={index} fontSize="16px">
                                    {' '}
                                    {descriptionItem}
                                </DescriptionText>
                            )
                        })}

                        {TechStack}
                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}

const MyTimelineItem: React.FC<ITimelineItem> = ({
    img,
    title,
    from,
    to,
    description,
    place,
    techStack
}) => {
    return (
        <TimelineItem>
            <TimelineOppositeContent sx={{ paddingLeft: 0, paddingRight: 1 }}>
                <TimelineText fontSize="14px">
                    {' '}
                    {from} - {to ?? ''}
                </TimelineText>
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ flex: 9, marginBottom: 4 }}>
                <TimelineItemInnerContent
                    techStack={techStack}
                    place={place}
                    title={title}
                    description={description}
                    img={img}
                />
            </TimelineContent>
        </TimelineItem>
    )
}

export default MyTimelineItem
