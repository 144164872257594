import * as React from 'react'

// import Stack from '@mui/material/Stack'
import { Container } from '@mui/material'

import Timeline from '@mui/lab/Timeline'

import MyTimelineItem from '../Components/TimelineItem'

import type { ITimelineContent } from '../Data/base'

const Layout: React.FC<{
    type: string
    title?: string
    sectionContents: ITimelineContent[]
}> = ({ sectionContents }) => {
    return (
        <Container
            sx={{
                paddingLeft: '0px !important',
                paddingRight: '0px !important'
            }}
        >
            <Timeline sx={{ marginTop: 0, padding: 0 }} position="right">
                {sectionContents.map((sectionItemContent, index) => {
                    return (
                        <MyTimelineItem
                            key={index}
                            isLast={index === 1}
                            place={sectionItemContent.place}
                            img={sectionItemContent.img}
                            title={sectionItemContent.title}
                            from={sectionItemContent.from}
                            to={sectionItemContent.to}
                            techStack={sectionItemContent.techStack}
                            description={sectionItemContent.description}
                        />
                    )
                })}
            </Timeline>
        </Container>
    )
}

export default Layout
